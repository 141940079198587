import React from "react";
import App from "next/app";
import Head from "next/head";
import ThemeProvider from "@pency/components/structure/ThemeProvider";
import FacebookPixel from "@pency/components/service/FacebookPixel";
import {appWithTranslation} from "next-i18next";
import '../ui/fonts.css'
import {META} from "../constants";

import {Provider as AnalyticsProvider} from "~/analytics/context";
import reporter from "~/reporting";

// Report unhandled rejection
process.on("unhandledRejection", (error: Error) => {
  reporter.exception(error, {origin: "signup | server | unhandledRejection"});
});

// Report unhandled exception
process.on("uncaughtException", (error: Error) => {
  reporter.exception(error, {origin: "signup | server | uncaughtException"});
});

class PencySignup extends App {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    reporter.exception(error, {extras: errorInfo, origin: "client"});

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const {Component, pageProps} = this.props;

    return (
      <ThemeProvider>
        <Head>
          <link href={META.favicon} rel="icon" />
          <link href={META.appleicon} rel="apple-touch-icon" />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap"
            rel="stylesheet"
          />
          <meta content={META.theme} name="theme-color" />
          <meta content={META.author} name="author" />
          <meta content={META.fbapp} property="fb:app_id" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content={META.twitter} name="twitter:site" />
          <meta content={META.twitter} name="twitter:creator" />
          <meta content={META.author} property="og:site_name" />
          <meta content="website" property="og:type" />
          <meta content={META.banner?.format} property="og:image:type" />
          <meta content={META.banner?.width} property="og:image:width" />
          <meta content={META.banner?.height} property="og:image:height" />
          {process.env.ENV === "production" && <FacebookPixel id="750875095735869" />}
        </Head>
        <AnalyticsProvider>
          <Component {...pageProps} />
        </AnalyticsProvider>
      </ThemeProvider>
    );
  }
}

export default appWithTranslation(PencySignup);
